import { PageId } from "../shared/enums/page-id";

export enum ButtonType {
  Next = 'submit',
  NextAccountConfirmation = 'next_account_confirmation',
  VerifyOtherAccount = 'verify_other_account',
  ApplyNow = 'apply_now',
  Contact = 'contact'
}

export enum SegmentEvent {
  // Click
  NextButtonClicked = 'Next Button Clicked',
  VerifyAnotherAccountClicked = 'Verify Another Account Button Clicked',
  ApplyNowButtonClicked = 'Apply Now Button Clicked',
  // Input
  FieldChanged = 'Field Changed',
  CheckboxChanged = 'Opt In Checkbox Toggled',
  ToggleChanged = 'Option Toggled',
  Contact = 'Home Contact Message Sent',
  OfferSelected = 'Offer Selected'
}

export const SEGMENT_IDENTIFY_CONTROL_NAMES = [
  'firstName',
  'lastName',
  'emailAddress',
  'email',
  'agree',
  'optInEmailMarketing',
  'optInEmailOperational',
  'optInSmsMarketing',
  'optInSmsOperational',
  'message',
  'phoneMobile',
  'isBusinessOwner',
  'companyName',
  'jobTitle',
  'companyStartDate',
  'address',
  'streetAddress',
  'state',
  'city',
  'zipCode',
  'birthDate',
  'ssn',
  'requestedAmount',
  'bankName',
  'bankRoutingTransitNumber',
  'bankAccountNumber'
];

export const SEGMENT_TRACK_CONTROL_NAMES = [
  'isBusinessOwnerOrIndContractor',
  'isForBusinessPurposes',
  'isBusinessOwner',
  'companyName',
  'jobTitle',
  'companyStartDate',
  'isMakeEnoughMoneyPerMonth',
  'isFreelancerOrGigWorker',
  'isW2Employee',
  'employmentStatusId',
  'employmentStatus',
  'netMonthlyIncome',
  'payFrequencyId',
  'paymentFrequency',
  'isBusinessSoleOwner',
  'programId',
  'termsAgreeCheckbox',
  'bankNoticeCheckbox',
  'signature',
  'sameDayFunding',
  'validSubmission',
  'accountNumberSelected',
  'programDuration',
  'offerAmount',
  'paymentAmount',
  'numberOfPayments'
];

export const UNTRACKED_PAGE_ID = [ PageId.Documents, PageId.References ];
