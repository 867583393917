import { AccountDetail } from '../services/types/account';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { getPageIdName, PageId } from '@shared/enums/page-id';
import _ from 'lodash';

import { ButtonType, SEGMENT_IDENTIFY_CONTROL_NAMES, SEGMENT_TRACK_CONTROL_NAMES, SegmentEvent, UNTRACKED_PAGE_ID } from '../constants/segment-tracking';
import { CalculatedOffers } from '../models/offer-calculation-response';
import { EMPLOYMENT_STATUS_OPTS } from '../new-pages/stepper-customer-info-page/constants/employment-status-options';
import { PAY_FREQUENCY_OPTS } from '../new-pages/stepper-customer-info-page/constants/payment-frequency-options';
import { camelToSnake } from '../utils/camel-to-snake-case';
import { camelToTitle } from '../utils/camel-to-title-case';

// Re-define Window for segment analytics
declare global {
  interface Window { analytics: any; }
}

@Directive({
  standalone: true,
  selector: '[foSegmentTrack]',
})
export class SegmentTrackDirective implements OnInit {

  /*
  * Properties containing data relevant to Segment tracking
  */ 
  @Input() properties: {
    pageId: PageId;
    formGroups?: FormGroup[];
    formControl?: FormControl;
    account?: AccountDetail;
    programGroup?: CalculatedOffers
  }; 

  /*
  * Element Asociated to the directive
  */ 
  element: HTMLElement;

  constructor(private el: ElementRef) {} 

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.element.addEventListener('click', (event: any) => this.trackClickEvent(event));

    // IMPORTANT: This functionality was intentionally bypassed due to business decisions.
    // More information about the specific business decisions can be found in [https://fundo.atlassian.net/wiki/spaces/FDO/pages/462487554/Segment.com].
    // this.element.addEventListener('focusout', (event: any) => this.trackFormControlChanges(event));
  }


  /**
   * Track Click events
   * @param {any} event - Click event 
   */
  private trackClickEvent(event: any) {
    const { target } = event || {};

    // Buttons
    if(!target) return;
    const buttonElement = target?.closest('UI-BUTTON') || target?.closest('BUTTON');
    const buttonType = buttonElement?.getAttribute("data-type") || buttonElement?.getAttribute("type");

    if(buttonElement && buttonType) {
      this.trackButtonClickEvent(buttonType);
      return;
    }
  }

  /**
   * Track Button Click
   * @param {string} buttonType - The Type of Button clicked by the user eg: Contact, Apply Now
   */
  private trackButtonClickEvent(buttonType: ButtonType): void {
    const { formGroups, formControl, account, pageId } = this.properties || {};

    const isValidSubmission = (): boolean | null => {
      return formGroups ? formGroups?.every(fg => fg.valid) : formControl ? formControl.valid : null
    };

    const getButtonSegmentEvent = (eventName: string) => `${getPageIdName(pageId)} ${eventName}`;

    let segmentEvent = getButtonSegmentEvent(SegmentEvent.NextButtonClicked);

    let payload: { [key:string]: any } | null = {
      validSubmission: isValidSubmission()
    };

    switch (buttonType) {
      case ButtonType.Next:
        payload = payload;

        // Customer Confirmation
        if(pageId === PageId.ConfirmationInfo) {
          const fg = formGroups?.find(fg => fg.get('emailAddress'));
          payload = this.getCustomerConfirmationPayload(payload, fg);
        }

        // Personal Details
        if(pageId === PageId.PersonalDetails) {
          const fg = formGroups?.find(fg => fg.get('state'));
          payload = this.getPersonalDetailsPayload(payload, fg);
        }
        // Bank Verification
        if(pageId === PageId.BankVerification) {
          const fg = formGroups?.find(fg => fg.get('bankName'));
          payload = this.getBankVerificationPayload(payload, fg);
        }
        break;
      case ButtonType.NextAccountConfirmation:
        payload['accountNumberSelected'] = account?.mask || null
        break;
      case ButtonType.VerifyOtherAccount:
        segmentEvent = getButtonSegmentEvent(SegmentEvent.VerifyAnotherAccountClicked);
        break;
      case ButtonType.Contact:
        segmentEvent = getButtonSegmentEvent(SegmentEvent.Contact);
        break;
      default:
        payload = null;
        console.warn(`trackButtonEvent: Unknown ButtonType: ${buttonType}`);
        break;
    }

    if(payload) {
      this.sendInfoToSegment(payload, segmentEvent);
    }
  }

  /**
   * Track Form Control Changes
   * @param {any} event - Change event for Inputs
   */
  private trackFormControlChanges(event: any) {
    if(!this.properties) return;

    const { target } = event || {};
    const { type } = target || {};
    const { formGroups, formControl, programGroup, pageId } = this.properties || {};

    // Form Control
    const formControlName = target.closest('[formControlName]')?.getAttribute('formControlName');
    const formGroup = formGroups?.find(fg => fg.get(formControlName));

    // Control could be a Form Control or single independent control
    const control = formGroup?.get(formControlName) || formControl;

    const segmentEvent = this.getSegmentEvent(formControlName, type);
    
    const shouldTrackPage =  !UNTRACKED_PAGE_ID.includes(pageId);
    const shouldTrackControl = shouldTrackPage && control?.valid && formControlName;

    if(shouldTrackControl) {
      const payload = this.getFieldChangedPayload(formControlName, programGroup, control);
      this.sendInfoToSegment(payload, segmentEvent);
    }
  }

/**
 * Prepares a payload object containing data for Segment for analytics tracking.
 * @param {string} formControlName The name of the form control
 * @param {CalculatedOffers} [programGroup] Program Group retrieved from Programs Page
 * @returns {{ [key: string]: any }} An object containing data for Segment tracking, with control names converted to snake_case.
 */
  private getFieldChangedPayload(
    formControlName: string, 
    programGroup?: CalculatedOffers,
    control?: AbstractControl
  ): { [key: string]: any } {
    let { value } = control || {};
    value = this.convertDateIfNecessary(value);

    // Program
    const shouldTrackProgramChange = formControlName === 'programId' && programGroup;

    if(shouldTrackProgramChange) {
      return {
        programDuration: programGroup.programGroupName,
        offerAmount: programGroup.offerAmount,
        paymentAmount: programGroup.acceptedWeeklyPaymentAmount,
        numberOfPayments: programGroup.weeklyInstallments,
      }
    } 
    return { [formControlName]: value }
  }

    /**
   * Extracts corresponding payload for Segment tracking.
   * @param {any} payload An existing payload object to be merged with (optional).
   * @param {FormGroup} [formGroup] A `FormGroup` object representing the personal details form.
   * @returns {{ [key: string]: any }}
   */

  // Customer Confirmation
  private getCustomerConfirmationPayload(payload: any, formGroup?: FormGroup) {
    if(!formGroup) return null;

    return {
      ...payload,
      ...formGroup.value
    }
  }

  // Personal Details
  private getPersonalDetailsPayload(payload: any, formGroup?: FormGroup) {
    if(!formGroup) return null;
    const { state, city, birthDate } = formGroup?.value || {};

    return {
      ...payload,
      state, 
      city, 
      birthDate: this.convertDateIfNecessary(birthDate)
    }
  }

  // Bank Verification
  private getBankVerificationPayload(payload: any, formGroup?: FormGroup) {
    if(!formGroup) return null;
    const { requestedAmount, bankName } = formGroup?.value || {};

    return { 
      ...payload,
      requestedAmount, 
      bankName
    }; 
  }

  /**
   * Determines the appropriate Segment event based on the control name, type, and other properties.
   * @param {string} controlName The name of the form control that triggered the event.
   * @param {string} type The type of control (e.g., checkbox, button).
   * @returns {string} The appropriate Segment event name based on the provided parameters.
   */
  private getSegmentEvent(controlName: string, type: string): string {
    const { pageId } = this.properties;
  
    // Special cases
    if (controlName === 'programId') {
      return SegmentEvent.OfferSelected;
    } else if (pageId === PageId.IncomeInfo || controlName === 'sameDayFunding') {
      return `${camelToTitle(controlName)} Changed`;
    }
  
    // Type-based mapping
    const eventMap: { [key: string]: any } = {
      checkbox: SegmentEvent.CheckboxChanged,
      button: SegmentEvent.ToggleChanged,
    };
  
    return eventMap[type] || SegmentEvent.FieldChanged; // Default to FieldChanged
  }

  /*
  * Segment tracking
  */ 
  private sendInfoToSegment(payload: any, event?: String) {
    const identifyPayload: {[key: string]: any } = {};
    const trackPayload: {[key: string]: any } = {};

    Object.keys(payload).forEach((controlName: string) => {
      const isSegmentIdentify = SEGMENT_IDENTIFY_CONTROL_NAMES?.includes(controlName);
      const isSegmentTrack = SEGMENT_TRACK_CONTROL_NAMES?.includes(controlName);

      const newControlValue = this.mapControlValue(payload[controlName], controlName);
      const newControlName = this.renameControlName(controlName);

      if(isSegmentIdentify) {
        identifyPayload[`${camelToSnake(newControlName)}`] = newControlValue;
      }

      if(isSegmentTrack) {
        trackPayload[`${camelToSnake(newControlName)}`] = newControlValue;
      }
    });

    // Segment tracking
    if(!_.isEmpty(identifyPayload)) {
      window.analytics.identify(identifyPayload);
    }

    if(!_.isEmpty(trackPayload) && event) {
      window.analytics.track(event, trackPayload);
    }
  }

  /*
  * Renames control name taking into account reserved words in Segment
  */ 
  private renameControlName(controlName: string): string {
    // Mapped Control name values
    const fieldNameMap = {
      employmentStatusId: 'employmentStatus',
      payFrequencyId: 'paymentFrequency',
      agree: 'optInAccepted',
      emailAddress: 'email',
      address: 'streetAddress'
    };

    const hasFieldName = (name: string): name is keyof typeof fieldNameMap => {
      return name in fieldNameMap;
    }

    // Rename Control name
    if (hasFieldName(controlName)) {
      controlName = fieldNameMap[controlName];
    }

    return controlName;
  }

  /*
  * Convert Date to string ISO format
  */ 
  private convertDateIfNecessary(value: any): any {
    if (value instanceof Date) {
      return value.toISOString();
    }
    return value;
  }

  /*
  * Map values to their corresponding semantic equivalent
  */ 
  private mapControlValue(value: any, controlName: string): string {
    let options : { text: string; value: number}[] = [];

    if(controlName === 'employmentStatusId') {
      options = EMPLOYMENT_STATUS_OPTS;
    }
    
    if(controlName === 'payFrequencyId') {
      options = PAY_FREQUENCY_OPTS;
    }

    return options.find(opt => opt.value === value)?.text ?? value; // Use default value if not found
  }

}

